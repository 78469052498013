import React from "react"
import Layout from "../../layouts/default"

import Breadcrumb from "../../components/breadcrumb"
import Constrain from "../../components/constrain"
import HyperCardMini from "../../components/hyper-card-mini"
import Paragraph from "../../components/paragraph"
import PageTitle from "../../components/page-title"
import Seo from "../../components/seo"
import Stack from "../../components/stack"

const MitarbeitenIndex = () => {
  return (
    <Layout backdrop="aussen">
      <Seo
        title="Praktikum & Projektmitarbeit"
        description=" Als kleines Museum mit großen Projekten freuen wir uns immer über Unterstützung! Bei einem Praktikum oder Freiwilligen Sozialen Jahr (FSJ) erhaltet ihr vielseitige Einblicke in die Arbeit unseres Museums."
      />
      <Stack space={[12, 24]}>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Mitarbeiten",
                link: "/mitarbeiten",
              },
            ]}
          />
          <PageTitle>Praktikum & Projektmitarbeit</PageTitle>
          <Paragraph dropcap={true}>
            Als kleines Museum mit großen Projekten freuen wir uns immer über
            Unterstützung! Bei einem Praktikum oder Freiwilligen Sozialen Jahr
            (FSJ) erhaltet ihr vielseitige Einblicke in die Arbeit unseres
            Museums. Darüber hinaus besteht die Möglichkeit, freiberuflich im
            Bereich der Ausstellungs- und Literaturvermittlung oder bei
            einzelnen aktuellen Projekten mitzuarbeiten.
          </Paragraph>
          <Constrain>
            <Stack space={6}>
              {/*
              <HyperCardMini
                headingElement="h2"
                title="Praktikum"
                description={`Du studierst Germanistik, Geschichte, Kulturwissenschaft oder ein vergleichbares Fach und möchtest Einblicke in die Praxis eines Literaturmuseums erhalten? Wir bieten Praktikumsplätze für die Dauer von 6 Wochen bis zu 6 Monaten an.`}
                to="/mitarbeiten/praktikum"
                cta="Mehr erfahren"
              />
              */}
              <HyperCardMini
                headingElement="h2"
                title="FSJ"
                description="Du interessierst dich für Literatur, bist aufgeschlossen, arbeitest gerne mit Menschen zusammen und möchtest mehr über die Arbeit eines Literaturmuseums erfahren? Dann bist du vielleicht genau die/der Richtige für ein Freiwilliges Soziales Jahr im Museum Hölderlinturm! Wir vergeben jedes Jahr zum Sommer/Herbst ein FSJ Kultur in unserem Museum."
                to="/mitarbeiten/fsj"
                cta="Mehr erfahren"
              />
              {/*
              <HyperCardMini
                headingElement="h2"
                title="Freie Mitarbeit Ausstellungsvermittlung"
                description="In freiberuflicher Mitarbeit können Sie sich in die Literatur- und Ausstellungsvermittlung unseres Museums mit einbringen und uns bei der Durchführung von Führungen und Workshops für verschiedene Zielgruppen unterstützen."
                to="/mitarbeiten/ausstellungsvermittlung"
                cta="Mehr erfahren"
              />
              */} 
            </Stack>
          </Constrain>
        </Stack>
      </Stack>
    </Layout>
  )
}

export default MitarbeitenIndex
