/** @jsx jsx */
import React from "react" // eslint-disable-line no-unused-vars
import PropTypes from "prop-types"
import { jsx, useThemeUI } from "theme-ui"

import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Box from "./box"
import Button from "./button"
import Paragraph from "./paragraph"
import Heading from "./heading"
import Icon from "./icon"
import Inline from "./inline"
import Stack from "./stack"
import Text from "./text"

const HyperCardMini = ({
  alt,
  attribution,
  collapse,
  cta,
  href,
  target,
  description,
  headingElement,
  image,
  title,
  to,
}) => {
  const context = useThemeUI()
  const { theme } = context

  const srcImage = getImage(image)

  return (
    <Box
      as={to ? Link : href ? "a" : "span"}
      to={to || null}
      href={href || null}
      target={target || null}
      sx={{
        display: "flex",
        flexDirection: collapse ? ["column", "row"] : "row",
        height: "100%",
        position: "relative",
        ".hyper-card-image, .hyper-card-box": {
          transition: `all ${theme.transitions["main"]}`,
        },
        ":hover .hyper-card-image, :focus .hyper-card-image": {
          transform: "scale(1.1)",
        },
        ":hover .hyper-card-button, :focus .hyper-card-button": {
          borderColor: "black",
        },
        ":hover .hyper-card-button *, :focus .hyper-card-button *": {
          color: "black",
        },
        ":hover .hyper-card-box, :focus .hyper-card-box": {
          bg: "primary",
        },
        ":hover .hyper-card-heading, :focus .hyper-card-heading": {
          color: "black",
        },
        ":hover .hyper-card-description, :focus .hyper-card-description": {
          color: "black",
        },
      }}
    >
      {srcImage && (
        <Box
          sx={{
            width: collapse ? ["100%", 48, 48, 56] : [48, null, null, 56],
            height: collapse ? [32, "auto"] : "auto",
            overflow: "hidden",
            position: "relative",
            flexShrink: 0,
          }}
        >
          <GatsbyImage
            className="hyper-card-image"
            alt={alt}
            image={srcImage}
            sx={{
              position: "absolute !important",
              left: 0,
              top: 0,
              right: 0,
              bottom: 0,
            }}
          />
          {attribution && (
            <Box position="absolute" bottom={0} left={0} right={0} p={3}>
              <Text size="10px" role="presentation" color="white" as="div" sans>
                <Inline space={1} alignX="right">
                  <span>Bild:</span>
                  <span>{attribution}</span>
                </Inline>
              </Text>
            </Box>
          )}
        </Box>
      )}
      <Box
        className="hyper-card-box"
        bg="muted"
        p={[6, 8]}
        sx={{
          display: "flex",
          width: "100%",
          height: "100%",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ pb: [6, 8] }}>
          <Stack space={3}>
            {title && (
              <Heading
                as={headingElement}
                className="hyper-card-heading"
                lang="de"
                hyphens="auto"
                level={5}
              >
                {title}
              </Heading>
            )}
            {description && (
              <Paragraph className="hyper-card-description" size={[2, 2, 3]}>
                {description}
              </Paragraph>
            )}
          </Stack>
        </Box>
        {cta && (
          <Box sx={{ flexShrink: 0 }}>
            <Button className="hyper-card-button" secondary size={1}>
              {cta}
            </Button>
          </Box>
        )}
      </Box>
      <Box
        position="absolute"
        bottom={0}
        right={0}
        zIndex="10"
        className="hyper-card-square"
        bg="primary"
        color="black"
        p={3}
        textAlign="center"
        display="inline-flex"
        alignX="center"
        alignY="center"
      >
        <Icon icon="arrow-right" />
      </Box>
    </Box>
  )
}

HyperCardMini.propTypes = {
  alt: PropTypes.string,
  cta: PropTypes.string,
  collapse: PropTypes.bool,
  headingElement: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  to: PropTypes.string,
  href: PropTypes.string,
}

HyperCardMini.defaultProps = {
  alt: "",
  headingElement: "h4",
  collapse: true,
}

export default HyperCardMini
